<template>
  <ErrorTemplate
    margin-after-image
    @onAction="onAction"
  />
</template>

<script>
import { getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import ErrorTemplate from './ErrorTemplate.vue'

export default {
  components: { ErrorTemplate },
  methods: {
    onAction() {
      const userData = getUserData()

      this.$router.replace(
        getHomeRouteForLoggedInUser(
          userData ? userData.role_name.toUpperCase() : null,
          userData ? userData.is_komship : null,
        ),
      )
    },
  },
}
</script>
